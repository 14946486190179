import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import axios, { AxiosError } from "axios"

import { getFilteredDiscountCodes } from "src/components/Account/BillingPortal/ChangePlan/discountCodeUtil"
import { StripeRegion } from "src/components/Account/BillingPortal/PaymentMethod/utils"
import { ICustomer, IPaymentSource } from "src/components/Account/types"
import { API_DEFAULT } from "src/constants/minutApi"
import {
  billingKeys,
  useBillingCache,
} from "src/data/billing/queries/billingQueryCache"
import {
  CouponErrorResponse,
  IAvailablePlansForUserResponse,
  ICreatePaymentSourceWithIntentBody,
  ICreateSubscriptionEstimateBody,
  IPaymentSourceDeleteResponse,
  IPaymentSourceResponse,
  IPostUpdateBillingInfo,
  IPostUpdateBillingInfoResponse,
  ISubscriptionEstimateBody,
  ISubscriptionEstimateResponse,
  ISubscriptionsBody,
  ISubscriptionsResponse,
  TCurrentSubscription,
  TSubscriptionError,
} from "src/data/billing/types/billingTypes"
import { useInvoiceCache } from "src/data/invoices/queries/invoiceQueryCache"
import { TUser } from "src/data/user/user"
import { isAxiosError } from "src/utils/genericUtil"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchAvailablePlansForUser({
  userId,
  options,
}: {
  userId: string
  options?: UseQueryOptions<
    IAvailablePlansForUserResponse,
    AxiosError,
    IAvailablePlansForUserResponse,
    ReturnType<typeof billingKeys.availablePlans>
  >
}) {
  /**https://api.staging.minut.com/v7/docs/internal#get-/subscriptions/available_plans/-userId- */
  async function fetchAvailablePlansForUser(userId: string) {
    const response =
      await minutApiHttpClient.get<IAvailablePlansForUserResponse>(
        `${API_DEFAULT}/subscriptions/available_plans/${userId}`
      )

    return response.data
  }

  return useQuery(
    billingKeys.availablePlans(userId),
    () => fetchAvailablePlansForUser(userId),
    options
  )
}

export function useFetchOrCreateCustomer({
  user,
  options,
}: {
  user: TUser
  options?: UseQueryOptions<
    ICustomer,
    AxiosError,
    ICustomer,
    ReturnType<typeof billingKeys.customer>
  >
}) {
  async function fetchOrCreateCustomer(user: TUser): Promise<ICustomer> {
    try {
      const response = await minutApiHttpClient.get(
        `${API_DEFAULT}/chargebee/customers/${user.user_id}`
      )

      const customer: ICustomer = response.data.customer
      return customer
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        const [firstName, ...lastName] = user.fullname.split(" ")
        const postResponse = await minutApiHttpClient.post(
          `${API_DEFAULT}/chargebee/customers`,
          {
            id: user.user_id,
            email: user.email,
            first_name: firstName,
            last_name: lastName.join(" "),
          }
        )
        const customer: ICustomer = postResponse.data.customer
        return customer
      } else {
        throw error
      }
    }
  }

  return useQuery(
    billingKeys.customer(user.user_id),
    () => fetchOrCreateCustomer(user),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000,
      ...options,
    }
  )
}

export function useFetchCurrentSubscription({
  userId,
  props,
}: {
  userId: string
  props?: {
    options?: UseQueryOptions<
      TCurrentSubscription | null,
      AxiosError<{ error_key: TSubscriptionError }>,
      TCurrentSubscription | null,
      ReturnType<typeof billingKeys.currentSubscription>
    >
  }
}) {
  const { options } = props || {}
  async function fetchCurrentSubscription() {
    const response = await minutApiHttpClient.get<TCurrentSubscription>(
      `${API_DEFAULT}/users/${userId}/current_subscription`,
      {
        params: {
          include_tax_exempt_prices: true,
        },
      }
    )
    const subscription = response.data
    return subscription
  }

  return useQuery({
    queryKey: billingKeys.currentSubscription(),
    queryFn: () => fetchCurrentSubscription(),
    ...options,
  })
}

export function useFetchPaymentMethod({
  paymentSourceId,
  options,
}: {
  paymentSourceId: string | undefined
  options?: UseQueryOptions<
    IPaymentSource,
    AxiosError,
    IPaymentSource,
    ReturnType<typeof billingKeys.paymentSource>
  >
}) {
  async function fetchPaymentMethod(): Promise<IPaymentSource> {
    const response = await minutApiHttpClient.get<IPaymentSourceResponse>(
      `${API_DEFAULT}/chargebee/payment_sources/${paymentSourceId}`
    )
    const paymentSourceResponse = response.data
    return paymentSourceResponse.payment_source
  }

  return useQuery(
    billingKeys.paymentSource(paymentSourceId ?? ""),
    () => fetchPaymentMethod(),
    {
      ...options,
      enabled:
        options?.enabled !== undefined
          ? !!paymentSourceId && options.enabled
          : !!paymentSourceId,
    }
  )
}

export function useDeletePaymentMethod() {
  const { updateCachedCustomer } = useBillingCache()
  const { removeCachedPaymentSource } = useBillingCache()

  async function deletePaymentMethod(
    paymentSourceId: string
  ): Promise<IPaymentSourceDeleteResponse> {
    const response =
      await minutApiHttpClient.delete<IPaymentSourceDeleteResponse>(
        `${API_DEFAULT}/chargebee/payment_sources/${paymentSourceId}/delete`
      )
    const result = response.data
    return result
  }

  return useMutation<
    IPaymentSourceDeleteResponse,
    AxiosError,
    {
      paymentSourceId: string
    }
  >(({ paymentSourceId }) => deletePaymentMethod(paymentSourceId), {
    onSuccess: (data: IPaymentSourceDeleteResponse) => {
      updateCachedCustomer(data.customer.id, () => data.customer)
      removeCachedPaymentSource()
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostRemoveScheduledCancellation<ErrorType = any>() {
  const { updateCachedCustomer, updateCachedSubscription } = useBillingCache()

  async function postRemoveScheduledCancellation(
    subscriptionId: string
  ): Promise<ISubscriptionsResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/subscriptions/${subscriptionId}/remove_scheduled_cancellation`
    )
    const result: ISubscriptionsResponse = response.data
    return result
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionId: string
    }
  >(({ subscriptionId }) => postRemoveScheduledCancellation(subscriptionId), {
    onSuccess: (data: ISubscriptionsResponse) => {
      updateCachedCustomer(data.customer.id, () => data.customer)
      updateCachedSubscription(() => data.subscription)
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostSubscriptions<ErrorType = any>() {
  const { updateCachedSubscription } = useBillingCache()

  async function postSubscriptions(data: ISubscriptionsBody) {
    const response = await minutApiHttpClient.post<ISubscriptionsResponse>(
      `${API_DEFAULT}/chargebee/subscriptions`,
      data
    )
    return response.data
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionBody: ISubscriptionsBody
    }
  >(({ subscriptionBody }) => postSubscriptions(subscriptionBody), {
    onSuccess: (data: ISubscriptionsResponse) => {
      updateCachedSubscription(() => data.subscription)
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostSubscription<ErrorType = any>() {
  const { updateCachedSubscription } = useBillingCache()

  async function postSubscription(
    id: string,
    data: ISubscriptionsBody
  ): Promise<ISubscriptionsResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/subscriptions/${id}`,
      data
    )
    const result: ISubscriptionsResponse = response.data
    return result
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionId: string
      subscriptionBody: ISubscriptionsBody
    }
  >(
    ({ subscriptionId, subscriptionBody }) =>
      postSubscription(subscriptionId, subscriptionBody),
    {
      onSuccess: (data: ISubscriptionsResponse) => {
        updateCachedSubscription(() => data.subscription)
      },
    }
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostCancelSubscription<ErrorType = any>() {
  const { updateCachedSubscription } = useBillingCache()

  async function postCancelSubscription(
    id: string,
    data: ISubscriptionsBody
  ): Promise<ISubscriptionsResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/subscriptions/${id}/cancel`,
      data
    )
    const result: ISubscriptionsResponse = response.data
    return result
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionId: string
      subscriptionBody: ISubscriptionsBody
    }
  >(
    ({ subscriptionId, subscriptionBody }) =>
      postCancelSubscription(subscriptionId, subscriptionBody),
    {
      onSuccess: (data: ISubscriptionsResponse) => {
        updateCachedSubscription(() => data.subscription)
      },
    }
  )
}

function isCouponError(err: unknown) {
  if (isAxiosError<CouponErrorResponse>(err)) {
    const couponNotApplicable =
      err.response?.status === 400 &&
      err.response.data.error_code === "coupon_not_applicable_for_plan"
    const couponExpired =
      err.response?.status === 400 &&
      err.response.data.error_code === "coupon_expired"
    const couponNotFound =
      err.response?.status === 404 &&
      err.response.data.error_code === "referenced_resource_not_found"
    if (!couponNotFound && !couponNotApplicable && !couponExpired) {
      return false
    }
    return true
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostUpdateSubscriptionEstimate<ErrorType = any>() {
  async function postUpdateSubscriptionEstimate(
    data: ISubscriptionEstimateBody,
    currentSubscription: TCurrentSubscription | undefined
  ): Promise<ISubscriptionEstimateResponse> {
    // Remove all invalid discount codes before updating subscription estimate:
    const body: ISubscriptionEstimateBody = {
      ...data,
      coupon_ids: getFilteredDiscountCodes({
        discountCodes: data.coupon_ids,
        currentSubscription,
      }),
    }

    try {
      const response =
        await minutApiHttpClient.post<ISubscriptionEstimateResponse>(
          `${API_DEFAULT}/chargebee/estimates/update_subscription`,
          body
        )

      return response.data
    } catch (err) {
      if (isCouponError(err)) {
        // If this happens, the coupon code applied is not valid, remove it
        // and retry the request
        const { coupon_ids, ...body } = data

        const response =
          await minutApiHttpClient.post<ISubscriptionEstimateResponse>(
            `${API_DEFAULT}/chargebee/estimates/update_subscription`,
            body
          )

        return response.data
      } else {
        throw err
      }
    }
  }

  return useMutation<
    ISubscriptionEstimateResponse,
    AxiosError<ErrorType>,
    {
      updateBody: ISubscriptionEstimateBody
      currentSubscription: TCurrentSubscription | undefined
    }
  >(
    ({ updateBody, currentSubscription }) =>
      postUpdateSubscriptionEstimate(updateBody, currentSubscription),
    {}
  )
}

export function usePostUpdateBillingInfo() {
  const { updateCachedCustomer } = useBillingCache()

  async function postUpdateBillingInfo({
    customerId,
    body,
  }: IPostUpdateBillingInfo): Promise<IPostUpdateBillingInfoResponse> {
    const response =
      await minutApiHttpClient.post<IPostUpdateBillingInfoResponse>(
        `${API_DEFAULT}/chargebee/customers/${customerId}/update_billing_info`,
        body
      )
    return response.data
  }

  return useMutation<
    IPostUpdateBillingInfoResponse,
    AxiosError,
    IPostUpdateBillingInfo
  >(postUpdateBillingInfo, {
    onSuccess: (data) => {
      updateCachedCustomer(data.customer.id, () => data.customer)
    },
  })
}

async function postCreateSubscriptionEstimate(
  data: ICreateSubscriptionEstimateBody
): Promise<ISubscriptionEstimateResponse> {
  try {
    const response =
      await minutApiHttpClient.post<ISubscriptionEstimateResponse>(
        `${API_DEFAULT}/chargebee/estimates/create_subscription`,
        data
      )

    return response.data
  } catch (err) {
    if (isCouponError(err)) {
      // If this happens, the coupon code applied is not valid, remove it
      // and retry the request
      const { coupon_ids, ...body } = data

      const response =
        await minutApiHttpClient.post<ISubscriptionEstimateResponse>(
          `${API_DEFAULT}/chargebee/estimates/create_subscription`,
          body
        )

      return response.data
    } else {
      throw err
    }
  }
}

export function useFetchCreateSubscriptionEstimates({
  bodies,
  options,
}: {
  bodies: ICreateSubscriptionEstimateBody[]
  options?: UseQueryOptions<
    ISubscriptionEstimateResponse[],
    AxiosError,
    ISubscriptionEstimateResponse[],
    ReturnType<typeof billingKeys.subscriptionEstimates>
  >
}) {
  return useQuery(
    billingKeys.subscriptionEstimates(bodies),
    () => Promise.all(bodies.map(postCreateSubscriptionEstimate)),
    {
      staleTime: 5 * 60 * 1000,
      ...options,
    }
  )
}

export function usePutReplacePrimaryPaymentSource() {
  const { invalidateCachedCustomer, removeCachedPaymentSource } =
    useBillingCache()
  const { resetInvoices } = useInvoiceCache()

  async function putReplacePrimaryPaymentSource(
    data: ICreatePaymentSourceWithIntentBody
  ): Promise<ICustomer> {
    const response = await minutApiHttpClient.put<{ customer: ICustomer }>(
      `${API_DEFAULT}/payment/replace_primary_payment_source`,
      data
    )
    const result = response.data.customer
    return result
  }

  return useMutation<
    ICustomer,
    AxiosError,
    {
      body: ICreatePaymentSourceWithIntentBody
    }
  >(({ body }) => putReplacePrimaryPaymentSource(body), {
    onSuccess: (customer: ICustomer) => {
      invalidateCachedCustomer(customer.id)
      removeCachedPaymentSource()
      resetInvoices()
    },
  })
}

export function usePostCreatePaymentSourceWithIntent() {
  const { updateCachedCustomer } = useBillingCache()
  const { resetInvoices } = useInvoiceCache()

  async function postCreatePaymentSourceWithIntent(
    data: ICreatePaymentSourceWithIntentBody
  ): Promise<ICustomer> {
    const response = await minutApiHttpClient.post<{ customer: ICustomer }>(
      `${API_DEFAULT}/chargebee/payment_sources/create_using_payment_intent`,
      data
    )
    const result = response.data.customer
    return result
  }

  return useMutation<
    ICustomer,
    AxiosError,
    {
      body: ICreatePaymentSourceWithIntentBody
    }
  >(({ body }) => postCreatePaymentSourceWithIntent(body), {
    onSuccess: (customer: ICustomer) => {
      updateCachedCustomer(customer.id, () => customer)
      resetInvoices()
    },
  })
}

export function usePostClientSecret() {
  async function postClientSecret(stripeRegion: StripeRegion) {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/payment/setup_intent`,
      {
        stripe_account_region: stripeRegion,
      }
    )
    return response.data?.client_secret
  }

  return useMutation<string, AxiosError, StripeRegion>(postClientSecret)
}
