import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TPlanUpgradeEventTypes = "Upgrade plan clicked"
export type TUpgradeBlockerContext =
  | "reports"
  | "violation-banner"
  | "settings-generic"
  | "editfield-generic"
  | "featureblocker-generic"

function usePostPlanUpgradeTrackingEvent<TEventProperties>(
  event: TPlanUpgradeEventTypes
) {
  return usePostTrackEventWithParams<TPlanUpgradeEventTypes, TEventProperties>({
    event,
  })
}

export type TPlanUpgradeClickedEventProperties = {
  context: TUpgradeBlockerContext
}
export function usePostPlanUpgradeClicked() {
  return usePostPlanUpgradeTrackingEvent<TPlanUpgradeClickedEventProperties>(
    "Upgrade plan clicked"
  )
}
