import { getHomeGroupAccessRank } from "src/data/homeGroups/logic/homeGroupsAccess"
import {
  HomeGroupRank,
  HomeGroupRole,
} from "src/data/homeGroups/types/homeGroupTypes"
import { getOrgAccessRank } from "src/data/organizations/logic/organizationAccess"
import {
  OrganizationRank,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import {
  AccessLevelRank,
  RestrictedFeature,
} from "src/data/user/types/accessTypes"
import { UserRoles } from "src/data/user/user"
import { arrayIncludesValueOfOtherArray } from "src/utils/genericUtil"

/** Check whether a given rank is allowed access to a feature */
export function allowedTo(
  feature: RestrictedFeature,
  rank: AccessLevelRank
): boolean {
  switch (feature) {
    case RestrictedFeature.TRANSFER_HOME_OWNERSHIP:
    case RestrictedFeature.DELETE_HOME:
    case RestrictedFeature.PAY_FOR_PLAN:
    case RestrictedFeature.REMOVE_MEMBERS:
    case RestrictedFeature.MAKE_ADMIN:
    case RestrictedFeature.OWNER_FEATURE:
      return rank >= AccessLevelRank.OWNER

    case RestrictedFeature.CHANGE_CONFIG:
    case RestrictedFeature.INVITE_MEMBERS:
    case RestrictedFeature.ADMIN_FEATURE:
      return rank >= AccessLevelRank.ADMIN

    case RestrictedFeature.VIEW_ALL_HOME_DATA:
    case RestrictedFeature.TOGGLE_SECURITY:
    case RestrictedFeature.ACCESS_CHAT_GROUPS:
      return rank >= AccessLevelRank.MEMBER
  }
  throw Error("Invalid feature") // If making an invalid call from regular JS
}

//#region Convenience functions
export function toggleSecurityAlarmAllowed(rank: AccessLevelRank | undefined) {
  const accessLevel = rank || 0
  return allowedTo(RestrictedFeature.TOGGLE_SECURITY, accessLevel)
}

export function toggleNoiseMonitoringAllowed(
  rank: AccessLevelRank | undefined
) {
  const accessLevel = rank || 0
  return allowedTo(RestrictedFeature.CHANGE_CONFIG, accessLevel)
}

export function toggleSmokingDetectionAllowed(
  rank: AccessLevelRank | undefined
) {
  const accessLevel = rank || 0
  return allowedTo(RestrictedFeature.CHANGE_CONFIG, accessLevel)
}

export function toggleFireCoAlarmAllowed(rank: AccessLevelRank | undefined) {
  const accessLevel = rank || 0
  return allowedTo(RestrictedFeature.CHANGE_CONFIG, accessLevel)
}

//#endregion Convenience functions

// #region organizations
const OrgAccessLogicBase = {
  hasOwnerAccess(role?: TOrganizationRole) {
    return getOrgAccessRank(role) >= OrganizationRank.OWNER
  },
  hasAdminAccess(role?: TOrganizationRole) {
    return getOrgAccessRank(role) >= OrganizationRank.ADMIN
  },
  hasMemberAccess(role?: TOrganizationRole) {
    return getOrgAccessRank(role) >= OrganizationRank.MEMBER
  },
}

/**
 * Use for determining org access levels via an OrganizationRole.
 *
 * The role param can come either from IOrganization.user_role or
 * IHome.user_role; note that user_role is context dependent on the user that
 * made the query to retrieve the object.
 *
 * In future iterations, we may also want to take the current plan and potential
 * violations into account.
 */
export function getAccessLogic({ role }: { role?: TOrganizationRole }) {
  const hasOwnerAccess = OrgAccessLogicBase.hasOwnerAccess(role)
  const hasAdminAccess = OrgAccessLogicBase.hasAdminAccess(role)
  const hasMemberAccess = OrgAccessLogicBase.hasMemberAccess(role)
  return {
    hasOwnerAccess,
    hasAdminAccess,
    hasMemberAccess,
  }
}
// #endregion organizations

// #region home groups
const homeGroupAccessLogicBase = {
  hasAdminAccess(role?: HomeGroupRole) {
    return getHomeGroupAccessRank(role) >= HomeGroupRank.ADMIN
  },
  hasMemberAccess(role?: HomeGroupRole) {
    return getHomeGroupAccessRank(role) >= HomeGroupRank.MEMBER
  },
}

/**
 * Use for determining home group access levels from the HomeGroupRole.
 */
export function getHomeGroupAccessLogic({ role }: { role?: HomeGroupRole }) {
  const hasAdminAccess = homeGroupAccessLogicBase.hasAdminAccess(role)
  const hasMemberAccess = homeGroupAccessLogicBase.hasMemberAccess(role)
  return {
    hasAdminAccess,
    hasMemberAccess,
  }
}
// #endregion home Groups

// #region paradise
export const rolesWithParadiseAccess = [
  UserRoles.SUPERADMIN,
  UserRoles.SALES_ADMIN,
] as const

export function hasAccessToParadise(userRoles: UserRoles[] | string[]) {
  return arrayIncludesValueOfOtherArray(rolesWithParadiseAccess, userRoles)
}

export function isSuperAdmin(userRoles: UserRoles[] | string[]) {
  return userRoles.includes(UserRoles.SUPERADMIN)
}

export function isSalesAdmin(userRoles: UserRoles[] | string[]) {
  return userRoles.includes(UserRoles.SALES_ADMIN)
}

// #endregion
