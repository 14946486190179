import qs from "qs"

import { IHomeFilterBody } from "src/data/homes/types/homeQueryTypes"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const Homes: ILocation<IHomeFilterBody | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return { pathname: `/homes` }
    }
    return { pathname: `/homes`, search: `?${queryFilter}` }
  },
} as const

const Home: ILocation<string> = {
  location(homeId) {
    const pathname = `${Homes.location().pathname}/${homeId}`
    return { pathname }
  },
} as const

const EditHomeAddress: ILocation<string> = {
  location(homeId) {
    const pathname = `${Home.location(homeId).pathname}/address`
    return { pathname }
  },
} as const

const CreateHome = createBasicLocation(
  `${Homes.location().pathname}/create-home`
)

export const homeAccessHash = "access"
const HomeAccess: ILocation = {
  location(homeId) {
    return { ...Home.location(homeId), hash: homeAccessHash }
  },
} as const

const AddVirtualDevice: ILocation<string> = {
  location(homeId) {
    const pathname = `${Home.location(homeId).pathname}/add-virtual-device`
    return { pathname }
  },
} as const

const HomeSettings: ILocation<string, { hash: string }> = {
  location(homeId) {
    return { ...Home.location(homeId), hash: "settings" }
  },
} as const

const HomeLog: ILocation<string, { hash: string }> = {
  location(homeId) {
    return { ...Home.location(homeId), hash: "log" }
  },
} as const

const HomeGuests: ILocation<string, { hash: string }> = {
  location(homeId) {
    return { ...Home.location(homeId), hash: "guests" }
  },
} as const

const HomeGuestsV2: ILocation<string> = {
  location(homeId) {
    const pathname = `${Home.location(homeId).pathname}/guests`
    return { pathname }
  },
}

const HomeSetup: ILocation<string> = {
  location(homeId) {
    const pathname = `${Home.location(homeId).pathname}/home-setup`

    return { pathname }
  },
}

export const HomeRoutes = {
  CreateHome,
  Homes,
  Home,
  HomeLog,
  HomeSettings,
  HomeGuests,
  HomeGuestsV2,
  HomeAccess,
  EditHomeAddress,
  AddVirtualDevice,
  HomeSetup,
} as const
