import { createRoot } from "react-dom/client"

import { initBackgroundMessageChannel } from "src/components/Notifications/notificationUtil"
import { initMobileAppAuth } from "src/data/auth/mobileAppAuth"
import { ErrorService } from "src/utils/ErrorService"

import { App } from "./App"

initMobileAppAuth()
initBackgroundMessageChannel()

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const rootElement = document.getElementById("root")
const root = createRoot(rootElement as HTMLElement)

ErrorService.init()

root.render(<App />)
