import { initializeApp } from "firebase/app"
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
  Unsubscribe,
} from "firebase/messaging"

export type { NotificationPayload } from "firebase/messaging"

import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { showMNotification } from "src/components/Notifications/notificationUtil"
import { FeatureFlag } from "src/constants/featureFlags"
import { retry } from "src/utils/genericUtil"
import { Logger } from "src/utils/logger"

// https://firebase.google.com/docs/cloud-messaging/js/client#configure_web_credentials_with
const vapidPubKey =
  "BEqNnUWVGTjMZnGeozYDocs1URk42vAawymIeqS4nVU3Xisat9GOU-s_AMBh--Lsy6WAlTmaXMAicMr8hOlv12I"

/**
 * Initialize Firebase
 *
 * https://firebase.google.com/docs/projects/learn-more#config-files-objects
 */
const app = initializeApp({
  apiKey: "AIzaSyA1uQNqw6f7rUdmn3h5InYGwWpN_Fv_OR0",
  authDomain: "point-b3426.firebaseapp.com",
  databaseURL: "https://point-b3426.firebaseio.com",
  projectId: "point-b3426",
  storageBucket: "point-b3426.appspot.com",
  messagingSenderId: "172222235180",
  appId: "1:172222235180:web:f67f6ffa9aede719bc8789",
  measurementId: "G-DN9T03EC25",
})
// Init messaging:
const messaging = getMessaging(app)

export async function getFirebaseToken() {
  try {
    // If there is no previously registered service worker, fcm.getToken() will
    // sometimes fail prematurely, before firebase has had a chance to set up
    // its service worker. To workaround this, we retry getToken() a few times.
    const fcmToken = await retry(
      () => getToken(messaging, { vapidKey: vapidPubKey }),
      { retries: 3, delay: 500 }
    )
    if (fcmToken) {
      firebaseLogger.info({ fcmToken })
      return fcmToken
    } else {
      firebaseLogger.log(
        "No registration token available. Request permission to generate one."
      )
      return null
    }
  } catch (err) {
    firebaseLogger.warn("An error occurred while retrieving token. ", err)
    return null
  }
}

export async function onFirebaseMessage(
  callback: (p: MessagePayload) => void
): Promise<Unsubscribe> {
  return onMessage(messaging, (payload) => {
    firebaseLogger.log({ payload })
    callback(payload)
  })
}

export function fcmPayloadParser(payload: MessagePayload) {
  const { title = "Minut", body = "" } = payload.data?.notification
    ? JSON.parse(payload.data.notification)
    : {}

  const eventData = payload.data?.eventsUpdated
    ? JSON.parse(payload.data.eventsUpdated)
    : {}
  const { homeId, organizationId } = eventData

  return { title, body, homeId, organizationId }
}

export function minutFcmMessageHandler({
  clickHandler,
  logHandler,
}: {
  clickHandler: (
    e: Event,
    props: { homeId: string; organizationId: string }
  ) => void
  logHandler: (p: { title: string; body: string }) => void
}) {
  return onFirebaseMessage((payload) => {
    const { title, body, homeId, organizationId } = fcmPayloadParser(payload)

    showMNotification({
      title,
      body,
      onClick: (e) => clickHandler(e, { homeId, organizationId }),
    })
    logHandler({ title, body })
  })
}

const firebaseLoggerEnabled = isStoredFeatureEnabled(FeatureFlag.LOGGING_DEBUG)
export const firebaseLogger = new Logger({
  enabled: firebaseLoggerEnabled,
  prefix: "[fcm]",
})
