import styled, { css } from "styled-components"

import { colorsV2 } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

export function MText({
  variant,
  color,
  marginBottom = 0,
  textAlign = "unset",
  as,
  style,
  classNames,
  hidden,
  children,
  ...props
}: MTextProps) {
  const _style = {
    color: getTextColor({ color }),
    marginBottom,
    textAlign,
    ...style,
  }

  switch (variant) {
    case "heading1":
      return (
        <Heading1 as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Heading1>
      )
    case "heading2":
      return (
        <Heading2 as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Heading2>
      )
    case "heading3":
      return (
        <Heading3 as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Heading3>
      )
    case "subtitle":
      return (
        <Subtitle as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Subtitle>
      )
    case "subtitleS":
      return (
        <SubtitleS as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </SubtitleS>
      )
    case "body":
      return (
        <Body as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Body>
      )
    case "bodyS":
      return (
        <BodyS as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </BodyS>
      )
    case "nano":
      return (
        <Nano as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Nano>
      )
    default:
      return (
        <Body as={as} style={_style} hidden={hidden} {...props}>
          {children}
        </Body>
      )
  }
}

function getTextColor({ color }: { color?: MTextProps["color"] }) {
  switch (color) {
    case "primary":
      return colorsV2.textPrimary
    case "secondary":
      return colorsV2.textSecondary
    case "tertiary":
      return colorsV2.textTertiary
    case "emergency":
      return colorsV2.systemErrorDark
    case "good":
      return colorsV2.systemGoodDark
    case "warning":
      return colorsV2.systemWarningDark
    case "unset":
      return "unset"
    case "contrast":
      return colorsV2.textContrast
    default:
      return colorsV2.textPrimary
  }
}

export type MTextProps = {
  variant?:
    | "heading1"
    | "heading2"
    | "heading3"
    | "subtitle"
    | "subtitleS"
    | "body"
    | "bodyS"
    | "nano"
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "emergency"
    | "good"
    | "warning"
    | "contrast"
    | "unset"
  marginBottom?: spacing | 0
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "justify-all"
    | "match-parent"
    | "inherit"
    | "initial"
    | "revert"
    | "revert-layer"
    | "unset"
  style?: React.CSSProperties
  classNames?: string
  hidden?: boolean
  as?: React.ElementType
  children: React.ReactNode
}

export const Heading1Mixin = css`
  font-size: 2rem;
  font-weight: ${fontWeight.regular};
  line-height: 1.4;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Heading1 = styled.div`
  ${Heading1Mixin}
`

export const Heading2Mixin = css`
  font-size: 1.5rem;
  font-weight: ${fontWeight.medium};
  line-height: 1.4;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Heading2 = styled.div`
  ${Heading2Mixin}
`

export const Heading3Mixin = css`
  font-size: 1.125rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.5;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Heading3 = styled.div`
  ${Heading3Mixin}
`

export const SubtitleMixin = css`
  font-size: 1rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.5;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Subtitle = styled.div`
  ${SubtitleMixin}
`

export const SubtitleSMixin = css`
  font-size: 0.875rem;
  font-weight: ${fontWeight.semiBold};
  line-height: 1.5;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const SubtitleS = styled.div`
  ${SubtitleSMixin}
`

export const BodyMixin = css`
  font-size: 1rem;
  font-weight: ${fontWeight.regular};
  line-height: 1.5;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Body = styled.div`
  ${BodyMixin}
`

export const BodySMixin = css`
  font-size: 0.875rem;
  font-weight: ${fontWeight.regular};
  margin: 0;
  line-height: 1.5;
  color: ${colorsV2.textPrimary};
`
const BodyS = styled.div`
  ${BodySMixin}
`

export const NanoMixin = css`
  font-size: 0.75rem;
  font-weight: ${fontWeight.regular};
  line-height: 1.5;
  margin: 0;
  color: ${colorsV2.textPrimary};
`
const Nano = styled.div`
  ${NanoMixin}
`
