import { TPlan } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const Account = createBasicLocation("/account")

const AccountGeneral: ILocation<void> = {
  location() {
    return {
      pathname: `${Account.location().pathname}/${langKeys.account}`,
    }
  },
} as const

const AccountBilling: ILocation<void> = {
  location() {
    return {
      pathname: `${Account.location().pathname}/${langKeys.settings_billing}`,
    }
  },
} as const

const ChangePlanSuccess = createBasicLocation(`/change-plan/success`)

const ChangePlan: ILocation<{ discountCode?: string; plan?: TPlan } | void> = {
  location(props) {
    const searchParams = new URLSearchParams()

    if (props?.discountCode) {
      searchParams.append("discount_code", props.discountCode)
    }

    if (props?.plan) {
      searchParams.append("plan", props.plan)
    }

    return { pathname: "/change-plan", search: searchParams.toString() }
  },
}

const CancelPlan = createBasicLocation(
  `${AccountBilling.location().pathname}/cancel-plan`
)

export const AccountRoutes = {
  Account,
  AccountGeneral,
  AccountBilling,
  ChangePlanSuccess,
  ChangePlan,
  CancelPlan,
} as const
