import { useState } from "react"
import styled from "styled-components"

import { Slider } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"

import {
  TVirtualDevice,
  TVirtualDeviceEnvironment,
} from "src/data/devices/types/deviceTypes"
import { usePostVirtualEnvironment } from "src/data/homes/queries/virtualDeviceQueries"
import { DEFAULT_HOME_ID } from "src/data/homes/types/homeTypes"
import { spacing } from "src/ui/spacing"

export function SliderSetting({
  orgId,
  simulatedDevice,
  Icon,
  label,
  step,
  min,
  max,
  marks,
  attribute,
  enabled,
}: {
  orgId: string
  simulatedDevice: TVirtualDevice
  Icon: React.ElementType
  label: string
  step: number
  min: number
  max: number
  marks: { value: number; label: string }[]
  attribute: keyof Pick<
    TVirtualDeviceEnvironment,
    | "sound_level"
    | "battery_voltage"
    | "temperature"
    | "humidity"
    | "wifi_rssi"
    | "mould_risk_level"
  >
  enabled?: boolean
}) {
  const postVirtualEnvironment = usePostVirtualEnvironment()
  const [state, setState] = useState(
    simulatedDevice.virtual_environment?.[attribute]
  )
  enabled = enabled ?? true

  return (
    <SliderWrapper>
      {postVirtualEnvironment.isLoading ? (
        <CircularProgress size={"24px"} />
      ) : (
        <Icon style={{ width: "30px" }} />
      )}
      <StyledSlider
        value={state}
        disabled={!enabled}
        onChange={(e, newValue) => {
          const value = Array.isArray(newValue) ? newValue[0] || 0 : newValue
          setState(value)
        }}
        onChangeCommitted={() => {
          postVirtualEnvironment.mutate({
            orgId,
            deviceId: simulatedDevice.device_id,
            homeId: simulatedDevice.home ?? DEFAULT_HOME_ID,
            body: {
              [attribute]: state,
            },
          })
        }}
        step={step}
        min={min}
        max={max}
        marks={marks}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}${label}`}
      />
    </SliderWrapper>
  )
}

const SliderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.XS};
  align-items: center;
`

const StyledSlider = styled(Slider)`
  max-width: 75%;
  display: block;
`
