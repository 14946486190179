import {
  Trans,
  // eslint-disable-next-line no-restricted-imports
  useTranslation,
} from "react-i18next"

import { TOptions } from "i18next"

import { langKeys } from "src/i18n/langKeys"

export type TTranslateFunction = ReturnType<typeof useTranslate>["t"]

export function useTranslate() {
  const { t: _t, i18n } = useTranslation()

  /** Convenience function for adding placeholder translations.
   *
   * Prefer this over `t` + langKeys.placeholder_web when you need to add a
   * placeholder translation; the reference lookup + deprecation indicator will
   * make usage of this function easier to find/spot.
   *
   * @deprecated
   */
  function tPlaceholder(text: string) {
    return t(langKeys.placeholder_web, { text })
  }

  /** Apply translation to a key. */
  function t(key: TLangKey, options?: TOptions): string {
    return _t(key, options)
  }

  return {
    Trans,
    t,
    langKeys,
    i18n,
    tPlaceholder,
    /** A reference to the react-i18next `t` function.
     * 
     * Use this only when you *know* you need to access the raw `i18n.t`
     * function and the stronger typed `t` function is insufficient.

     * Use `t` instead, whenever possible; this function does not perform any
     * lang key type checking.
    */
    _t,
  }
}

export type TLangKeys = typeof langKeys
export type TLangKey = keyof TLangKeys
