import { IJwtData } from "src/data/auth/authTypes"
import { IAuthorization, TMaybeAuthorization } from "src/data/user/user"
import { MLocation } from "src/router/routeTypes"
import { debug } from "src/utils/logger"
import {
  localStorageFactory,
  sessionStorageFactory,
} from "src/utils/storageUtil"

export const authTokenStorage = localStorageFactory<IAuthorization>({
  key: "minut.authenticated_user",
})

export const storedLocation = sessionStorageFactory<MLocation>({
  key: "minut.stored_location",
  setEncoder(value) {
    debug.log(`setting ${this.key}`, value)
    return JSON.stringify(value)
  },
})

interface IImpersonateStorage {
  user_id: string
  expires_at: number
}
export const impersonateStorage = sessionStorageFactory<IImpersonateStorage>({
  key: "minut.impersonated_user",
})

export const codeVerifierStorage = sessionStorageFactory<string>({
  key: "minut.auth_code_verifier",
})

export const clearAuthStorage = () => {
  authTokenStorage.clear()
  impersonateStorage.clear()
  codeVerifierStorage.clear()
}

//==============================================================================
// Authorization accessors & setters
//==============================================================================
export function getJwtFromAccessToken(accessToken: string) {
  const [protocol, data] = accessToken.split(".")
  debug.log("JWT", { protocol, data })
  const jwtBin = window.atob(data)
  const jwtParsed: IJwtData = JSON.parse(jwtBin)
  return jwtParsed
}

// Auth
export const setStoredAuthorization = (a: TMaybeAuthorization) => {
  a ? authTokenStorage.set(a) : authTokenStorage.clear()
}
export const getStoredAuthorization = () => authTokenStorage.get()
export const getStoredRefreshToken = () => authTokenStorage.get()?.refresh_token

// Impersonate
export const setStoredImpersonation = (a: TMaybeAuthorization) => {
  if (!a) {
    setStoredAuthorization(null)
    impersonateStorage.clear()
    return
  }
  const jwtData = getJwtFromAccessToken(a.access_token)
  setStoredAuthorization(a)
  impersonateStorage.set({ user_id: a.user_id, expires_at: jwtData.exp })
}
export const getStoredImpersonation = () => impersonateStorage.get()
export const getImpersonateActive = () => !!getStoredImpersonation()?.user_id
