import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import ImportantSvg from "src/ui/icons/important-outlined.svg"
import InfoSvg from "src/ui/icons/info-i.svg"

type MInfoProps = {
  content: React.ReactNode
  iconProps?: {
    type?: "info" | "important"
    width?: string | number
    height?: string | number
  }
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>
  interactive?: boolean
  tooltipProps?: Omit<
    React.ComponentProps<typeof Tooltip>,
    "children" | "title"
  >
}

export function MInfo({
  content,
  iconProps,
  tooltipProps,
  interactive = false,
  wrapperProps,
}: MInfoProps) {
  const { height = 24, width = 24, type = "info" } = iconProps || {}
  function Icon() {
    switch (type) {
      case "info":
        return <InfoSvg width={width} height={height} />
      case "important":
        return <ImportantSvg width={width} height={height} />
      default:
        return <InfoSvg width={width} height={height} />
    }
  }

  return (
    <Tooltip
      interactive={interactive}
      disableFocusListener
      title={content ?? ""}
      {...tooltipProps}
    >
      <IconWrapper {...wrapperProps}>
        <Icon />
      </IconWrapper>
    </Tooltip>
  )
}

// N.B: A wrapping element is required for the MUI Tooltip to work correctly
const IconWrapper = styled.div``
