// Store global hrefs here, rather than inlining them in the code.

import { isParadiseProdEnv } from "src/constants/env"
import { getStoredAuthorization } from "src/data/auth/auth"
import { IParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"

export const HREF_MINUT_STORE = "https://store.minut.com"

export const HREF_MINUT_STAGING_STORE = "https://store.staging.minut.com"

export const HREF_MINUT_BILLING = "/billing"

export const HREF_MINUT_HELP = "https://support.minut.com"

export const HREF_MINUT_TERMS_OF_SERVICE =
  "https://www.minut.com/service/terms-of-service/"

export const HREF_MINUT_PRIVACY_POLICY = `https://www.minut.com/service/privacy-policy/`

export const HREF_MINUT_HELP_CROWD_DETECT =
  "https://support.minut.com/en/articles/5020397-crowd-detect"

export const HREF_MINUT_HELP_HOME_PROFILES =
  "https://support.minut.com/en/articles/5172900-profiles"

export const HREF_MINUT_HELP_SHORTCODES =
  "https://support.minut.com/en/articles/5356196-guest-connect-check-in-check-out-message"

export const HREF_MINUT_MRS_BILLING_REGIONS =
  "https://support.minut.com/en/articles/5967200-currently-available-locations-and-billing"

export const HREF_MINUT_HELP_LEGACY_PLANS =
  "https://support.minut.com/en/articles/6167256-legacy-plans-and-changes-to-current-plans"

export const HREF_MINUT_HOME_GROUPS =
  "https://support.minut.com/en/articles/6457273-organizations-home-groups"

export const HREF_MINUT_ORGANIZATIONS_MEMBER_INVITE =
  "https://support.minut.com/en/articles/6457223-organizations-how-to-invite-members"

export const HREF_MINUT_ORGANIZATION_PERMISSIONS =
  "https://support.minut.com/en/articles/6515398-organizations-members-roles-access-level"

export const HREF_MINUT_HELP_SENSORS_OFFLINE =
  "https://support.minut.com/en/articles/7872966-what-to-do-when-your-sensor-turns-offline"

export const HREF_MINUT_HELP_SENSOR_SWAP =
  "https://support.minut.com/en/articles/7964094-sensor-swap"

export const HREF_MINUT_GUARD_ASSIST =
  "https://support.minut.com/en/collections/3322493-guard-assist-previously-minut-response-partners"

export const HREF_MINUT_HELP_CALL_ASSIST =
  "https://support.minut.com/en/articles/8376790-what-is-call-assist"

export const HREF_MINUT_REGISTER_GUARD_ASSIST =
  "https://minut-hq.typeform.com/to/xKdeHG3E"

export const HREF_MINUT_MARKETING_CALL_ASSIST_INFO =
  "https://minut.com/product/call-assist"

export const HREF_MINUT_MARKETING_GUARD_ASSIST_INFO =
  "https://minut.com/product/guard-assist"

export const HREF_MINUT_INTEGRATION_LET_US_KNOW =
  "https://minut-hq.typeform.com/to/LK1Hobs9?typeform-source=www.minut.com"

export const HREF_MINUT_SUBSCRIPTION_TERMS =
  "https://www.minut.com/legal/subscription-terms"

export const HREF_CIGARETTE_FALSE_NEGATIVE_REPORT_FORM =
  "https://forms.gle/r2Nk5SmAweMrfDqq6"

export const HREF_MINUT_HELP_INDOOR_CLIMATE_MONITORING =
  "http://support.minut.com/en/articles/9208390-indoor-climate-monitoring"

export const HREF_MINUT_HELP_DESKTOP_NOTIFICATIONS =
  "https://support.minut.com/en/articles/9361239-desktop-notifications"

function accountManageHref({
  accessToken,
  path,
  redirectToWeb,
}: {
  accessToken?: string
  path: string
  redirectToWeb?: boolean
}) {
  const url = new URL(path, import.meta.env.REACT_APP_API_URL)

  if (accessToken) {
    url.searchParams.set("session_from_token", accessToken)
  }

  if (redirectToWeb) {
    url.searchParams.set("redirectToWeb", "true")
  }

  return url.toString()
}

export function changePasswordUrl() {
  return accountManageHref({
    accessToken: getStoredAuthorization()?.access_token,
    path: "change_password",
    redirectToWeb: true,
  })
}

export function resetPasswordUrl() {
  return accountManageHref({ path: "account/password" })
}

export function kibanaUrl(userId: string) {
  const baseUrl = isParadiseProdEnv
    ? "https://kibana.minut.com"
    : "https://kibana.staging.minut.com"

  return `${baseUrl}/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-30m,mode:quick,to:now))&_a=(columns:!(_source),index:'logs-*',interval:m,query:(query_string:(analyze_wildcard:!t,query:'${userId}')),sort:!('@timestamp',desc))`
}

export function chargebeeUrl(userId: string) {
  const baseUrl = isParadiseProdEnv
    ? "https://minut.chargebee.com"
    : "https://minut-test.chargebee.com"

  return `${baseUrl}/admin-console/customers/${userId}`
}

export function intercomUrl(email: string) {
  return `https://app.intercom.io/a/apps/noqdedef/inbox/inbox/search?q=${email}`
}

export function kibanaDeviceUrl(device: IParadiseDevice) {
  return `https://kibana.minut.com/_plugin/kibana/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-2h,mode:quick,to:now))&_a=(columns:!(module,msg),index:'point-*',interval:auto,query:(query_string:(analyze_wildcard:!t,query:'device:${
    device.hardware_version < 100
      ? device.device_mac.substring(6, 12)
      : device.device_mac
  }')),sort:!('@timestamp',desc))`
}

export function firmwareCrashReportUrl(deviceMac: string) {
  return `https://fwcrs.minut.com/dash/reports?mac=${deviceMac}`
}

export const paradiseUrl = {
  production: "https://paradise2.minut.com",
  staging: "https://paradise2.staging.minut.com",
}

export const webUrl = {
  production: "https://web.minut.com",
  staging: "https://web.staging.minut.com",
}
