/* eslint-disable no-console */
import { Component, lazy } from "react"

import { sessionStorageFactory } from "src/utils/storageUtil"

const MAX_NBR_RELOADS = 3

const errorReloadCycles = (name: string) =>
  sessionStorageFactory<number>({
    key: `minut.nbrReloads.${name}`,
  })

class TempComponent extends Component {
  render() {
    return null
  }
}

/**
 * React named export lazy loader.
 *
 * Adapted from https://www.npmjs.com/package/react-lazily
 *
 * Triggers a window reload on error, but gives up after MAX_NBR_RELOADS; mainly
 * for handling 'error loading dynamically imported module' exceptions
 * transparently.
 */
export const lazily = <T extends object, U extends keyof T>(
  loader: (x?: string) => Promise<T>
) =>
  new Proxy({} as unknown as T, {
    get(target, componentName: string | symbol) {
      if (typeof componentName === "string") {
        return lazy(() =>
          loader(componentName)
            .then((x) => {
              if (errorReloadCycles(componentName).get() != null) {
                console.log(`${componentName}: Success, clearing import errors`)
                errorReloadCycles(componentName).clear()
              }

              return {
                default: x[
                  componentName as U
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ] as any as React.ComponentType<any>,
              }
            })
            .catch((e) => {
              const nbrReloads = errorReloadCycles(componentName).get() || 0
              console.warn("lazily import error", { componentName, nbrReloads })

              if (nbrReloads > MAX_NBR_RELOADS) {
                throw new Error("Reloading dynamic module did not help")
              }
              errorReloadCycles(componentName).set(nbrReloads + 1)
              window.location.reload()
              return { default: TempComponent } // return an empty object while page reloads
            })
        )
      }
    },
  })
