import { SUPPORTED_LANGUAGES } from "src/i18n/supportedLanguages.mjs"

/**
 * Check if a language is valid. This function can e.g. be used to decide
 * if the users selected preferred language should be rendered or not
 * based on what we support in the web app
 */

export function isValidLng(lng: string) {
  return SUPPORTED_LANGUAGES.includes(lng.split("-")[0])
}
