import { MLink, MLinkProps } from "src/ui/Link/MLink"

export function ExternalLink({
  openInNewTab = true,
  showExternalIcon = true,
  ...rest
}: MLinkProps) {
  return (
    <MLink
      openInNewTab={openInNewTab}
      showExternalIcon={showExternalIcon}
      {...rest}
    />
  )
}
