// Module containing utility functions for running the web app embedded inside
// the Minut Mobile clients.

import {
  getStoredAuthorization,
  setStoredAuthorization,
} from "src/data/auth/authStorage"
import { IAuthorization } from "src/data/user/user"
import { APPLOGIN } from "src/router/routerQueryParams"

const MINUT_MOBILE_UA = "Minut Mobile Webview"

declare global {
  // eslint-disable-next-line no-var -- var is required here
  var minut: {
    printAuth: () => void
    login: (credentials: IAuthorization) => void
  }
}

const minutMobileAppAuth = {
  /**
   * Exposes a global 'printAuth' function as a convenient way to get the current
   * login credentials.
   *
   * @returns IAuthorization
   */
  printAuth() {
    return getStoredAuthorization()
  },

  /**
   * Exposes a global 'login' function as a way to trigger a login via javascript.
   *
   * The mobile apps will leverage this when running the web app in embedded mode.
   * @param credentials IAuthorization
   */
  login(credentials: IAuthorization) {
    if (!credentials?.access_token) {
      throw Error("Access token needed")
    }
    if (!credentials.user_id) {
      throw Error("User id needed")
    }

    const url = new URL(window.location.href)
    // Remove applogin query param:
    url.searchParams.delete(APPLOGIN)

    // Store external credentials:
    setStoredAuthorization(credentials)

    // Reload app with new credentials:
    window.location.replace(url)
  },
}

export const initMobileAppAuth = () => {
  globalThis.minut = minutMobileAppAuth
}

export function shouldAwaitAppLogin(authorized: boolean | undefined) {
  const url = new URL(window.location.href)
  const appLogin = url.searchParams.get(APPLOGIN)
  return !!appLogin
}

export function isMinutMobile() {
  const isMinutMobileApp = navigator.userAgent.includes(MINUT_MOBILE_UA)
  return !!isMinutMobileApp
}
