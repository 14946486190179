import { createContext, useCallback, useEffect, useState } from "react"

import {
  browserPermissionStatus,
  getBrowserNotificationState,
  showMNotification,
  TMNotificationParams,
  TNotificationPermission,
} from "src/components/Notifications/notificationUtil"
import { useAppData } from "src/context/useAppData"
import {
  firebaseLogger,
  getFirebaseToken,
  minutFcmMessageHandler,
  NotificationPayload,
} from "src/data/notifications/firebase"
import { usePostPushNotificationRegistration } from "src/data/notifications/pushNotificationQueries"
import { usePutUser } from "src/data/user/queries/userQueries"
import { TUser } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { Result } from "src/utils/tsUtil"

type TEnableErrors = "permission-denied" | "generic-error" | "token-error"
type TEnableResult = Result<boolean, TEnableErrors>

const Accept = (value: boolean): TEnableResult => ({ ok: true, value })
const Reject = (error: TEnableErrors): TEnableResult => ({
  ok: false,
  error,
})

export const NotificationContext = createContext({
  loading: false,
  /** `true` if notifications are enabled for this browser. */
  notificationsEnabled: false,
  /** Set notification enabled state. */
  setNotificationsEnabled: async (
    enabled: boolean
  ): Promise<TEnableResult> => ({ ok: false }),
  /**
   * Display a notification if notifications are enabled; else noop.
   * params: https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#parameters
   */
  showNotification: (params: TMNotificationParams) => {},
  /** Contains active firebase token if initialized. */
  get firebaseToken(): null | string {
    return null
  },
  /** Returns the browser notification permission state. */
  get browserPermission(): TNotificationPermission {
    return getBrowserNotificationState()
  },
  /** Contains a log for all received notifications. */
  get notificationLog(): NotificationPayload[] {
    return []
  },

  // Debugging:
  get backendNotificationsEnabled(): boolean | undefined {
    return undefined
  },
  setBackendNotificationsEnabled: async (
    v: TUser["desktop_notifications_enabled"]
  ): Promise<TUser | undefined> => undefined,
})

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { user } = useAppData()
  const { navigate } = useRouter()

  const [toggling, setToggling] = useState(false)
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null)
  const [notificationLog, setNotificationLog] = useState<NotificationPayload[]>(
    []
  )

  const backendNotificationsEnabled = user?.desktop_notifications_enabled

  const postPushNotificationRegistration = usePostPushNotificationRegistration()
  const postPushNotificationRegistrationMutateAsync =
    postPushNotificationRegistration.mutateAsync // referentially stable; can be used in useEffect

  const putBackendNotificationState = usePutBackendNotificationState(
    user?.user_id
  )

  // Handler for incoming Firebase Cloud Messaging messages
  const addFcmHandler = useCallback(async () => {
    return minutFcmMessageHandler({
      clickHandler(e, { homeId, organizationId: orgIdOverride }) {
        window.focus()
        navigate(Routes.Home.location(homeId), { orgIdOverride })
        e.preventDefault()
      },
      logHandler({ title, body }) {
        // Append notification to log; retain only the last 101 notifications:
        setNotificationLog((log) => [
          ...(log.slice(-100) || []),
          { title, body },
        ])
      },
    })
  }, [navigate])

  // Set notifications enabled state in backend and browser.
  async function _setNotificationsEnabled(
    wantedValue: boolean
  ): Promise<TEnableResult> {
    try {
      setToggling(true)
      await browserPermissionStatus.init()

      const userId = user?.user_id
      if (!userId) throw Error("Tried to toggle notifications without user id")

      if (wantedValue === false) {
        setFirebaseToken(null)
        await putBackendNotificationState(wantedValue)
        return Accept(wantedValue) // user disabled notifications
      }

      const browserPermission =
        await browserPermissionStatus.requestPermission(wantedValue)
      const browserPermissionGranted = browserPermission === "granted"

      if (!browserPermissionGranted && wantedValue === true) {
        setFirebaseToken(null)
        return Reject("permission-denied") // user rejected notifications
      }

      const updatedUser = await putBackendNotificationState(true)

      const backendHasEnabledNotifications =
        !!updatedUser?.desktop_notifications_enabled

      if (!backendHasEnabledNotifications) {
        setFirebaseToken(null)
        return Reject("generic-error")
      }

      const token = await getFirebaseToken()
      setFirebaseToken(token)
      if (!token) {
        return Reject("token-error")
      }

      const result = await postPushNotificationRegistrationMutateAsync({
        userId,
        token,
      })

      await addFcmHandler()

      firebaseLogger.warn(
        "Notifications successfully set to",
        result.desktop_notifications_enabled
      )
      return Accept(!!result.desktop_notifications_enabled)
    } catch (e) {
      firebaseLogger.error(e)
      return Reject("generic-error")
    } finally {
      setToggling(false)
    }
  }
  const setNotificationsEnabled = useCallback(_setNotificationsEnabled, [
    addFcmHandler,
    postPushNotificationRegistrationMutateAsync,
    putBackendNotificationState,
    user?.user_id,
  ])

  const notificationsEnabled =
    !!backendNotificationsEnabled &&
    !!browserPermissionStatus.granted &&
    !!firebaseToken

  // Setup firebase cloud messaging on initial app load
  const [retries, setRetries] = useState(0)
  useEffect(() => {
    async function setupNotificationsInitial() {
      if (getBrowserNotificationState() !== "granted") {
        return // only run if browser has notifications enabled
      }
      if (retries > 1) {
        return // only try running this effect once
      }
      if (toggling) {
        return // don't run if already loading
      }
      if (backendNotificationsEnabled !== true) {
        return // only run if user has notifications enabled in the backend
      }
      if (notificationsEnabled) {
        return // don't run if notifications are already enabled
      }

      try {
        setToggling(true)
        setRetries((r) => r + 1)
        firebaseLogger.log("Desktop notifications initialization started")
        const { ok } = await setNotificationsEnabled(true)
        if (ok) {
          setRetries(0)
        } else {
          firebaseLogger.warn("Desktop notifications initialization failed")
        }
      } finally {
        setToggling(false)
      }
    }
    setupNotificationsInitial()
  }, [
    backendNotificationsEnabled,
    toggling,
    notificationsEnabled,
    retries,
    setToggling,
    setNotificationsEnabled,
  ])

  return (
    <NotificationContext.Provider
      value={{
        loading: toggling,
        notificationsEnabled,
        setNotificationsEnabled,
        firebaseToken,
        browserPermission: browserPermissionStatus.value,
        showNotification: showMNotification,
        notificationLog,
        // Debugging:
        backendNotificationsEnabled,
        setBackendNotificationsEnabled: putBackendNotificationState,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

function usePutBackendNotificationState(userId: string | undefined) {
  const putUser = usePutUser()
  async function putBackendNotificationState(
    v: TUser["desktop_notifications_enabled"]
  ) {
    return putUser.mutateAsync({
      userId: userId ?? "me",
      userData: { desktop_notifications_enabled: v },
    })
  }
  return putBackendNotificationState
}
