import {
  HomeGroupRank,
  HomeGroupRole,
} from "src/data/homeGroups/types/homeGroupTypes"

/** Get the access rank of a role */
export function getHomeGroupAccessRank(
  role: HomeGroupRole | undefined
): HomeGroupRank {
  switch (role) {
    case HomeGroupRole.ADMIN:
      return HomeGroupRank.ADMIN
    default:
      return HomeGroupRank.MEMBER
  }
}

export function hasHomeGroupAccess(
  role: HomeGroupRole | undefined,
  minLevel: HomeGroupRole = HomeGroupRole.MEMBER
): boolean {
  if (role === undefined) {
    return false
  }
  const rank = getHomeGroupAccessRank(role)
  const minRank = getHomeGroupAccessRank(minLevel)
  return rank >= minRank
}
