export const DEVICE_LIST_LIMIT = 30
export const DEVICE_LIST_LIMIT_MAX = 60
export const DEVICE_LOW_BATTERY_LIMIT = 5 // XXX: Align with battery.ts:BATTERY_THRESHOLD_BAD

export const p1DeviceReadingType = [
  "temperature",
  "humidity",
  "sound_level",
  "pressure",
  "part_als",
  "part_als_ir",
  "sound_avg_levels",
  "sound_peak_levels",
  "battery",
  "sound_level_dba",
] as const

export const p2DeviceReadingType = [
  "temperature",
  "humidity",
  "sound_level",
  "motion_events",
  "pressure",
  "sound_avg_levels",
  "sound_peak_levels",
  "wifi_signal",
  "ambient_light",
  "battery",
  "battery_charging_current",
  "battery_temperature",
  "tvoc_ppb",
  "accelerometer_x",
  "accelerometer_y",
  "accelerometer_z",
  "magnetic_flux",
  "smoke_detector",
  "carbon_monoxide",
  "sound_level_dba",
  "occupancy",
] as const

export const deviceReadingType = [
  "battery_charging",
  "charger_connected",
  "wlan_rssi_dbm",
  "wlan_boot_time_ms",
  "wlan_join_time_ms",
  "dhcp_init_time_ms",
  "dhcp_lease_cached",
  "dhcp_initial_lease_time_seconds",
  "dhcp_remaining_lease_time_seconds",
  "os_heap_free_bytes",
  "os_heap_free_minimum_bytes",
  "os_uptime_secs",
  "fs_free_bytes",
  "cellular_rsrp",
  "cellular_rsrq",
  "cellular_rssi",
  "cellular_cell_id",
  "cellular_tracking_area_code",
  "cellular_access_type",
  "cellular_edrx_req",
  "cellular_edrx_nw",
  "cellular_edrx_ptw",
  "cellular_psm_tau_req",
  "cellular_psm_act_req",
  "cellular_psm_tau_nw",
  "cellular_psm_act_nw",
  "heartbeat_delay_ms",
] as const

export enum PointMetrics {
  BATTERY_CHARGING = 1,
  CHARGER_CONNECTED = 2,

  WLAN_RSSI_DBM = 16,
  WLAN_BOOT_TIME_MS = 17,
  WLAN_JOIN_TIME_MS = 18,
  DHCP_INIT_TIME_MS = 19,
  DHCP_LEASE_CACHED = 20,
  DHCP_INITIAL_LEASE_TIME_SECONDS = 25,
  DHCP_REMAINING_LEASE_TIME_SECONDS = 26,

  OS_HEAP_FREE_BYTES = 21,
  OS_HEAP_FREE_MINIMUM_BYTES = 22,
  OS_UPTIME_SECS = 23,
  FS_FREE_BYTES = 24,

  CELLULAR_RSRP = 27,
  CELLULAR_RSRQ = 28,
  CELLULAR_CELL_ID = 29,
  CELLULAR_TRACKING_AREA_CODE = 30,
  CELLULAR_ACCESS_TYPE = 31,
  CELLULAR_RSSI = 32,

  CELLULAR_EDRX_REQ = 33,
  CELLULAR_EDRX_NW = 34,
  CELLULAR_EDRX_PTW = 35,

  CELLULAR_PSM_TAU_REQ = 36,
  CELLULAR_PSM_ACT_REQ = 37,
  CELLULAR_PSM_TAU_NW = 38,
  CELLULAR_PSM_ACT_NW = 39,

  HEARTBEAT_DELAY_MS = 40,
}

export const hardwareRevision: Record<number, string> = {
  0: "R1A",
  1: "R2A",
  2: "R2B",
  3: "R2C",
  4: "R2D",
  5: "R2E",
  6: "R2F",
  7: "R2H",
  8: "R2J",
  100: "R1A",
  101: "R1B",
  102: "R1E",
  103: "R2C",
  104: "R3B",
  105: "R3C",
  106: "R3D",
  107: "R3E",
  108: "R3F",
  109: "R3G",
  110: "R3H",
  111: "R3J",
  112: "R3K",
  113: "R3L",
  150: "R4A",
  151: "R4B",
  200: "StackA",
  210: "R1A",
  211: "R1B",
  212: "R1C",
  255: "RXX",
  300: "R1A",
}
