import { createContext } from "react"

import { useFlagProvider } from "src/components/Flags/useFlagProvider"
import { IAuthState, useAuthState } from "src/context/auth/useAuthState"
import {
  clearAuthStorage,
  getImpersonateActive,
} from "src/data/auth/authStorage"
import { isMinutMobile } from "src/data/auth/mobileAppAuth"
import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { useActiveOrganizationId } from "src/data/organizations/hooks/useActiveOrganizationId"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { useFetchActiveOrganization } from "src/data/organizations/queries/organizationQueries"
import {
  DEFAULT_ORG_ID,
  IOrganization,
} from "src/data/organizations/types/organizationTypes"
import { useFetchUser } from "src/data/user/queries/userQueries"
import { DEFAULT_USER_ID, TUser } from "src/data/user/user"
import { ErrorService } from "src/utils/ErrorService"
import { debug, logger } from "src/utils/logger"
import { TMaybeAxiosError } from "src/utils/tsUtil"

export const AppDataContext = createContext({
  get user(): TUser | undefined {
    return undefined
  },
  loading: false,
  get criticalError(): TMaybeAxiosError {
    return null
  },
  get authState(): IAuthState {
    return {
      authorization: null,
      setAuthorization(): void {},
    }
  },
  ready: false,
  refetchActiveOrg(): void {},
  clear(): void {},
  get activeOrg(): IOrganization | null | undefined {
    return null
  },
  setActiveOrgId(id: string | null): void {},

  get numberOfHomes(): number | undefined {
    return undefined
  },

  get currentSubscription(): TCurrentSubscription | undefined | null {
    return undefined
  },
})

/**
 * This context takes care of fetching and keeping track of organization data,
 * which is vital for the web app to function.
 *
 * Whenever changing the behavior of anything related this context, make sure to
 * test the following:
 *
 * 1. Create organization
 * 2. Delete organization
 * 3. Login
 * 4. Logout
 * 5. Logout clearing local data
 * 6. Joining organization
 * 7. Leaving organization
 * 8. Being removed from an organization
 * 9. Invite flow
 * 10. Creating account as part of invite flow
 * 11. Ending up without org
 * 12. Creating an account and completing onboarding.
 */
export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const flagProvider = useFlagProvider()
  const authState = useAuthState()
  const authStateUserId = authState.authorization?.user_id

  const fetchUser = useFetchUser({
    userId: authStateUserId,
    options: {
      staleTime: Infinity,
      cacheTime: 0, // clear user as soon as authStateUserId changes
      enabled: !!authStateUserId,
      onSuccess(user) {
        flagProvider.toggles.setRoles(user.roles)
        const impersonating = getImpersonateActive()

        ErrorService.setUser({
          user: {
            id: user.user_id,
            email: user.email,
            name: user.fullname,
            impersonating,
          },
        })
      },
      onError(err) {
        logger.error(err)
        if (!isMinutMobile()) {
          // Calling `clear` here causes race conditions when trying to embed
          // the web app inside a mobile app web view. Furthermore, it's
          // incorrect to use these query callbacks as explained by the react
          // query documentations, so at some point we should probably remove
          // this callback entirely.
          clear()
        }
      },
    },
  })
  const user = fetchUser.data

  const { setActiveOrgId, storedActiveOrgId } = useActiveOrganizationId()
  const fetchActiveOrganization = useFetchActiveOrganization({
    orgId: storedActiveOrgId,
    onFallback: (fallbackOrgId) => {
      // make sure that we update the query params and local storage if we fallback
      setActiveOrgId(fallbackOrgId)
    },
    options: {
      enabled: !!user,
      staleTime: Infinity,
    },
  })
  const activeOrg = fetchActiveOrganization.data

  const fetchHomesCount = useFetchHomesCount({
    orgId: activeOrg?.id || DEFAULT_ORG_ID,
    options: { enabled: !!activeOrg?.id },
  })
  const numberOfHomes = fetchHomesCount.data

  const fetchCurrentSubscription = useFetchCurrentSubscription({
    userId: user?.user_id ?? DEFAULT_USER_ID,
    props: {
      options: {
        enabled: !!user?.user_id,
        retry(failureCount, error) {
          if (failureCount < 5 && error.code === "ERR_NETWORK") {
            debug.log("Network error, retry attempt", failureCount)
            return true
          }
          return failureCount < 3
        },
      },
    },
  })

  function clear() {
    debug.log("Clear AppData")
    authState.setAuthorization(null)
    clearAuthStorage()
  }

  const loading =
    fetchUser.isInitialLoading ||
    fetchActiveOrganization.isInitialLoading ||
    fetchHomesCount.isInitialLoading ||
    isCurrentSubscriptionLoading(fetchCurrentSubscription)

  // If there is an issue with a query that is critical for the application, it
  // should be propagated as an error to the application. E.g. a middleware
  // might log out the user in that case. Errors on requests that have to do with
  // requests made for analytics related data is not something that needs
  // to be checked here.
  const criticalError =
    fetchUser.error ||
    fetchActiveOrganization.error ||
    isCritialSubscriptionError(fetchCurrentSubscription.error)
      ? fetchCurrentSubscription.error
      : null

  const readyArray = [
    !!authState.authorization,
    !loading,
    !criticalError,
    !!user,
    !!activeOrg,
  ]

  const ready = readyArray.every(Boolean)

  return (
    <AppDataContext.Provider
      value={{
        ready,
        user,
        criticalError,
        loading,
        authState,
        clear,
        setActiveOrgId,
        activeOrg,
        numberOfHomes,
        currentSubscription: fetchCurrentSubscription.data,
        refetchActiveOrg: fetchActiveOrganization.refetch,
      }}
    >
      {/* NB: Even if the app data is not loaded, or is unavailable, we need
        to always render the children in order to be able to show non-private
        routes, e.g., the login page.

        Our PrivateRoute wrapper will handle the loading state for routes that
        require an authenticated user with an active organization.*/}
      {children}
    </AppDataContext.Provider>
  )
}

function isCurrentSubscriptionLoading(
  fetchCurrentSuscription: ReturnType<typeof useFetchCurrentSubscription>
) {
  // If the user does not have any subscription we get a 404 i.e an error
  // `isInitialLoading` would always be true until we get a success response
  // Therefore we have to check if we have ever fetched the data no matter response as well
  if (
    fetchCurrentSuscription.isInitialLoading &&
    !fetchCurrentSuscription.isFetched
  ) {
    return true
  }

  return false
}

function isCritialSubscriptionError(
  error: ReturnType<typeof useFetchCurrentSubscription>["error"]
) {
  if (!error) {
    return false
  }

  // 404s are no critical errors, without this the app would error-out for users without a subscription
  if (error.response?.status === 404) {
    return false
  }

  return true
}
