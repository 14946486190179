import { langKeys } from "src/i18n/langKeys"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const Settings = createBasicLocation(`/settings`)

const MonitoringSettings = createBasicLocation(
  `${Settings.location().pathname}/${langKeys.monitoring}`
)

const MonitoringSettingEdit: ILocation<string> = {
  location(profileId) {
    const pathname = `${
      MonitoringSettings.location().pathname
    }/edit/${profileId}`
    return { pathname }
  },
}
const MonitoringSettingAdd = createBasicLocation(
  `${MonitoringSettings.location().pathname}/add`
)

const OrganizationSettings = createBasicLocation(
  `${Settings.location().pathname}/${langKeys.organization}`
)

const ApiClientsSetting = createBasicLocation(
  `${Settings.location().pathname}/api-clients`
)

const ApiClientsCreate = createBasicLocation(
  `${ApiClientsSetting.location().pathname}/create`
)

const ApiClientsEdit: ILocation<string> = {
  location(clientId) {
    const pathname = `${ApiClientsSetting.location().pathname}/edit/${clientId}`
    return { pathname }
  },
}

export const SettingsRoutes = {
  MonitoringSettings,
  MonitoringSettingAdd,
  MonitoringSettingEdit,
  OrganizationSettings,
  Settings,
  ApiClientsSetting,
  ApiClientsCreate,
  ApiClientsEdit,
}
