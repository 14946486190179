import qs from "qs"

import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import {
  createBasicLocation,
  ILocation,
  IPathMixin,
  ITabsMixin,
} from "src/router/routeTypes"

const HOME_GROUP_ROUTE = "/home-groups"

const CreateHomeGroup = createBasicLocation(`${HOME_GROUP_ROUTE}/new`)

const HomeGroupDetails: ILocation<string> &
  ITabsMixin<readonly ["homes", "members"]> &
  IPathMixin<string> = {
  location: (id) => {
    const pathname = `${HOME_GROUP_ROUTE}/details/${id}`
    return { pathname }
  },
  path(id) {
    return this.location(id).pathname
  },
  tabs: ["homes", "members"],
} as const

const HomeGroups: ILocation<{ limit?: number; offset?: number } | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return {
        pathname: `${HOME_GROUP_ROUTE}`,
        search: `?limit=${HOME_LIST_LIMIT}&offset=0`,
      }
    }
    return { pathname: `${HOME_GROUP_ROUTE}`, search: `?${queryFilter}` }
  },
}

const HomeGroupAddHomes: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/homes/add-homes`,
    }
  },
} as const

const HomeGroupAddMember: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/members/add-member`,
      hash: "home-group-access",
    }
  },
} as const

const HomeGroupAddMemberEmail: ILocation = {
  location(homeGroupId: string) {
    const pathname = `${HomeGroupDetails.path(homeGroupId)}/add-email-access`
    return { pathname, hash: HomeGroupDetails.tabs[1] }
  },
} as const

export const HomeGroupRoutes = {
  HomeGroupDetails,
  HomeGroups,
  HomeGroupAddHomes,
  HomeGroupAddMember,
  HomeGroupAddMemberEmail,
  CreateHomeGroup,
} as const
