import {
  IBasePaginatedEndpoint,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"
import { Maybe } from "src/utils/tsUtil"

export interface IHomeGroup {
  id: string
  name: string
  organization_id: string
  created_at: string // format: date-time
  updated_at?: string // format: Date-time
  user_role?: HomeGroupRole
}

export interface IHomeGroupMember {
  member_id: string
  user_id: string
  joined_at: string // format: date-time
  role: HomeGroupRole
  name: string
  email: string
}

export interface IFetchHomeGroupMembers extends IBasePaginatedEndpoint {
  members: IHomeGroupMember[]
}

export enum HomeGroupRole {
  OWNER = "owner",
  ADMIN = "admin",
  MEMBER = "member",
  RESPONDER = "responder",
}

export enum HomeGroupRank {
  RESPONDER,
  MEMBER,
  ADMIN,
}

export interface IFetchHomeGroups extends IBasePaginatedEndpoint {
  homegroups: IHomeGroup[]
}

export interface IFetchHomeGroupHomes extends IBasePaginatedEndpoint {
  homes: IHomeGroupHome[]
}

export interface IPostHomeGroup {
  name: string
}

export interface IPostHomeGroupHome {
  home_id: string
}

export interface IHomeGroupHome {
  id: string
  name: string
}

export interface IPatchHomeGroup extends IPostHomeGroup {}

export interface IHomeGroupFilter extends IPagingFilter {
  name?: string | number | null
  role?: HomeGroupRole
}

export interface IHomeGroupHomesFilter extends IPagingFilter {}

export type TMaybeHomeGroup = Maybe<IHomeGroup>
