import { useState } from "react"
import styled from "styled-components"

import { Switch, Tooltip } from "@material-ui/core/"
import { useIsMounted } from "usehooks-ts"

import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingToggle({
  title,
  description,
  value,
  onSave,
  toggleProps = { trueVal: true, falseVal: false },
  errorInitial,
  children,
  ...props
}: ISettingContainerBaseProps & {
  toggleProps?: IToggleProps
  errorInitial?: string
  onSave(newVal: string | boolean): TSettingContainerOnSaveReturnType
  value: string | boolean
  children?: ReturnType<typeof SettingContainer>[] | React.ReactNode
}) {
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(errorInitial ?? "")

  const { t } = useTranslate()

  const isMounted = useIsMounted()

  const showChildren = value === toggleProps.trueVal && !!children

  return (
    <div>
      <SettingContainer
        title={title}
        description={description}
        onSave={async () => ({ isSuccess: true })}
        displayValue={null}
        InputComponent={() => (
          <Input
            value={value}
            setSaveValue={async (saveVal) => {
              setSaving(true)
              const response = await onSave(saveVal)
              setSaving(false)

              if (isMounted()) {
                if (response.isSuccess) {
                  setError("")
                } else {
                  setError(
                    response.message ?? t(langKeys.failed_general_error_title)
                  )
                }
                setSaving(false)
              }
            }}
            {...toggleProps}
            disabled={saving || props.disabled}
          />
        )}
        inlineEdit
        {...props}
      />

      {error && (
        <ErrorBox type="error" fullWidth>
          {error}
        </ErrorBox>
      )}

      {showChildren && !error && children}
    </div>
  )
}

function Input({
  value,
  setSaveValue,
  trueVal = true,
  falseVal = false,
  ...toggleProps
}: {
  value: string | boolean
  setSaveValue(newVal: boolean | string): void
} & IToggleProps) {
  return (
    <Tooltip title={toggleProps.toolTipText || ""}>
      <div>
        <Switch
          checked={value === trueVal}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            const newVal = Boolean(ev.target.checked) ? trueVal : falseVal
            setSaveValue(newVal)
          }}
          disabled={toggleProps.disabled}
        />
      </div>
    </Tooltip>
  )
}

interface IToggleProps {
  trueVal: string | true
  falseVal: string | false
  disabled?: boolean
  toolTipText?: string
}

const ErrorBox = styled(MBanner)`
  margin-top: ${spacing.M};
  margin-bottom: ${spacing.XS};
`
