import axios, { AxiosError } from "axios"

import { debug } from "src/utils/logger"

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Use when you wish to check if a string can be coerced into a number */
export function isNumber(s: string | number | null | undefined) {
  // return !!s && +s === +s // eslint-disable-line no-self-compare
  return !isNil(s) && s !== "" && +s === +s // eslint-disable-line no-self-compare
}

/** Use when you wish to check if something is a string */
export function isString(thingToCheck: unknown): thingToCheck is string {
  return typeof thingToCheck === "string" || thingToCheck instanceof String
}

/** Coerce a string into a number */
export function asNumber(s: string | null | undefined): number | undefined {
  if (!isNumber(s)) {
    return undefined
  }
  return parseFloat(String(s))
}

/** Use when you wish to check if a string can be coerced into a boolean */
export function isBoolean(s: string | boolean | null | undefined) {
  return s === "false" || s === "true" || s === true || s === false
}

/** Coerce a string into a boolean */
export function asBoolean(
  s: string | boolean | null | undefined
): boolean | undefined {
  if (!isBoolean(s)) {
    return undefined
  }
  return s === "true" || s === true
}

export function stringSplice(orig: string, index: number, str: string) {
  return orig.slice(0, index) + str + orig.slice(index)
}

/** This function does not currently handle deep equality checks */
export function isEqual(obj1: object = {}, obj2: object = {}) {
  const sortedObj1 = Object.entries(obj1).sort()
  const sortedObj2 = Object.entries(obj2).sort()
  return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2)
}

export function unique<T = string>(a: T[] | undefined) {
  return [...new Set(a)]
}

/**
 * A function that checks if the passed parameter is Nil (null or undefined) and
 * narrows it's type accordingly

 * https://github.com/remeda/remeda/blob/v0.0.32/src/guards.ts#L187
 */
export function isNil<T>(data: T): data is Extract<T, null | undefined> {
  return data == null
}

/** Returns true if value is nil or an empty object/array */
function empty(value: unknown): boolean {
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  if (isObject(value) && Object.keys(value).length === 0) {
    return true
  }
  return isNil(value)
}

/**
 * This function takes any kind of generic, arbitrarily nested object and
 * returns a copy without 'empty' fields, i.e., an object where all keys that
 * point to undefined, null or an empty object are recursively removed.
 */
export function purgeEmpty<T>(o: DefinitelyObject<T>): DefinitelyObject<T> {
  if (!isObject(o)) {
    return o
  }

  // this is an object; recurse through its fields, filtering out empty objects
  const copy = Object.entries(o).filter(([key, value]) => {
    const subObject = purgeEmpty(value)
    return !empty(subObject)
  })
  return Object.fromEntries(copy) as DefinitelyObject<T>
}

type DefinitelyObject<T> =
  Exclude<
    Extract<T, object>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    Array<unknown> | Function | ReadonlyArray<unknown>
  > extends never
    ? { [k: string]: unknown }
    : Exclude<
        Extract<T, object>,
        // eslint-disable-next-line @typescript-eslint/ban-types
        Array<unknown> | Function | ReadonlyArray<unknown>
      >
/**
 * A function that checks if the passed parameter is of type Object and narrows
 * it's type accordingly.
 *
 * Inspired by Remeda's isObject function:
 * https://github.com/remeda/remeda/blob/v0.0.32/src/guards.ts#L149
 */
export function isObject<T>(data: T | object): data is DefinitelyObject<T> {
  return !!data && !Array.isArray(data) && typeof data === "object"
}

export function slugify(s: string) {
  return s
    .toLowerCase()
    .replace(/[^\w ]+/g, "") // remove all non-alphanumerical characters except space
    .replace(/ +/g, "-") // replace all spaces with dashes
}

export function chunk<T>(array: T[], size?: number) {
  const chunkSize = size || 1
  return array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    resultArray[chunkIndex] = [...(resultArray[chunkIndex] ?? []), item]
    return resultArray
  }, [])
}

/** Use to get types axios errors */
export function isAxiosError<T = unknown>(
  payload: unknown
): payload is AxiosError<T> {
  return axios.isAxiosError(payload)
}

export function arrayIncludesValueOfOtherArray<T>(
  arr: readonly T[],
  comparer: readonly T[]
) {
  return arr.some((v) => comparer.includes(v))
}

/* A function that will retry a promise until it resolves or the max number of
 * retries is reached.
 *
 * @param fn - The function to retry
 * @param retries - The number of retries to attempt
 * @param delay - The delay in milliseconds between retries
 */
export async function retry<T>(
  fn: () => Promise<T>,
  { retries = 3, delay = 500 }: { retries?: number; delay?: number } = {}
): Promise<T> {
  try {
    return await fn()
  } catch (error) {
    if (retries <= 0) {
      throw error
    }
    debug.info("Retrying after error:", error)
    await sleep(delay)
    return retry(fn, { retries: retries - 1, delay })
  }
}
