import { FunctionComponent } from "react"
import styled from "styled-components"

// eslint-disable-next-line no-restricted-imports
import * as Sentry from "@sentry/react"

import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MLink } from "src/ui/Link/MLink"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface IErrorBoundary extends Sentry.ErrorBoundaryProps {
  children: React.ReactNode
  wrapper?: FunctionComponent
  mini?: boolean
  internalLink?: boolean
}

const chunkLoadingErrorString = "dynamically imported module"

export function ErrorBoundary({
  children,
  wrapper: WrapperComponent,
  mini,
  internalLink,
  ...props
}: IErrorBoundary) {
  const Fallback = WrapperComponent ? (
    <WrapperComponent>
      <ErrorFallback mini={mini} />
    </WrapperComponent>
  ) : (
    <ErrorFallback mini={mini} />
  )

  const fallbackRenderer: Sentry.FallbackRender = (errorData) => {
    if (errorData.error.message.includes(chunkLoadingErrorString)) {
      // In the unlikely case that hard reloading is not handled by
      // vendor.lazily.ts, we fallback also hard reloading here
      // eslint-disable-next-line no-console
      console.warn("!!! Using fallback hard reload !!!")
      window.location.reload()
      return <LoadingScreen debugInfo="Hard reloading app" />
    }
    return Fallback
  }

  return (
    <Sentry.ErrorBoundary {...props} fallback={fallbackRenderer}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

function ErrorFallback({
  mini,
  internalLink,
}: {
  mini?: boolean
  internalLink?: boolean
}) {
  const DashBoardLink = () =>
    internalLink ? (
      <InternalLink to={Routes.Dashboard.location()}>
        Go to Dashboard
      </InternalLink>
    ) : (
      <MLink href={Routes.Dashboard.location().pathname}>Go to Dashboard</MLink>
    )

  if (mini) {
    return (
      <MBanner type="error">
        We're having issues loading this page. Please try again later.{" "}
        <DashBoardLink />
      </MBanner>
    )
  }

  return (
    <Box>
      <MText variant="heading2">Something went wrong</MText>

      <div>
        We're having issues loading this page. Please try again, or contact
        support at hello@minut.com if the problem remains.
      </div>

      <DashBoardLink />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing.S};
  font-size: 0.975rem;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`
