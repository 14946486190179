import { useRef, useState } from "react"
import styled from "styled-components"

import { Fade, IconButton, Paper } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Popper from "@material-ui/core/Popper"

import {
  TUpgradeBlockerContext,
  UpgradeBlockerButton,
} from "src/components/FeatureBlockers/UpgradeBlockerButton"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import UpgradeIcon from "src/ui/icons/arrow-up-filled-upgrade.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function UpgradeIconButton({
  className,
  context,
  requiredPlan,
}: {
  className?: string
  requiredPlan?: TPlan
  context: TUpgradeBlockerContext
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const iconButtonRef = useRef<HTMLDivElement | null>(null)
  const { t, langKeys } = useTranslate()

  const { org } = useOrganization()
  const isOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess

  function openPopper(element: HTMLElement) {
    setAnchorEl(element)
  }

  function closePopper() {
    setAnchorEl(undefined)
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (anchorEl) {
      closePopper()
    } else {
      openPopper(event.currentTarget)
    }
  }

  function handleClickAway() {
    closePopper()
  }

  return (
    <div
      className={className}
      onMouseEnter={() =>
        iconButtonRef.current && openPopper(iconButtonRef.current)
      }
      onMouseLeave={closePopper}
      ref={iconButtonRef}
    >
      <IconButton size="small" onClick={handleClick}>
        <UpgradeIcon />
      </IconButton>

      <UpgradePopper open={!!anchorEl} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={250}>
              <ContentBox>
                <CopyBox>
                  <UpgradeIcon />
                  <TextBox>
                    <MText variant="subtitleS" color="primary">
                      {t(langKeys.subscription_upgrade_blocker_title)}
                    </MText>
                  </TextBox>
                </CopyBox>

                <UpgradeBlockerButton
                  size="small"
                  context={context}
                  disabled={!isOwner}
                  plan={requiredPlan}
                />
                {!isOwner && (
                  <MBanner type="error" fullWidth>
                    {t(langKeys.subscription_upgrade_blocker_title_non_owner)}
                  </MBanner>
                )}
              </ContentBox>
            </Fade>
          </ClickAwayListener>
        )}
      </UpgradePopper>
    </div>
  )
}

const UpgradePopper = styled(Popper)`
  z-index: ${Z_INDEX_ABOVE_MODAL};
`

const CopyBox = styled.div`
  display: flex;
  align-items: center;
`

const TextBox = styled.div`
  margin-left: ${spacing.XS};
`

const ContentBox = styled(Paper)`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.M};
  flex-wrap: wrap;
  padding: ${spacing.M};
  max-width: 450px;
`
